import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';
import axios from 'axios';
import ApolloMarkdown from '../framework/components/ApolloMarkdown';
import BackgroundComponent from './BackgroundComponent';

// @ts-ignore
import image from '../assets/images/footerfoto.jpg';

import './scss/Footer.scss';
import useApiUrl from '../framework/hooks/useApiUrlHook';

export default function Footer() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const apiUrl = useApiUrl();

    const [page, setPage] = useState('form');
    const [isInView, setIsInView] = useState(false);

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    const onSubmit = async (data) => {
        if (apiUrl === null) return;

        await axios.post(`${apiUrl}/form/contact`, data).then((response) => {
            if (response.data) {
                setPage('bedankt');
            }
        });
    };

    return (
        <footer>
            <Waypoint onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container contact prettyForm">
                    <div className="row">
                        <div className="col col--6">
                            {page === 'form' ? (
                                <>
                                    <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                        <div className="pre">We hopen dat je geïnteresseerd bent</div>
                                        <h2>
                                            <ApolloMarkdown inline>Meer **informatie ontvangen?**</ApolloMarkdown>
                                        </h2>
                                    </div>
                                    <p>
                                        Laat hier je naam en telefoonnummer achter. Wij bellen je dan zo spoedig
                                        mogelijk terug! Of stuur ons een mailtje naar{' '}
                                        <a href="mailto:rijssen@pngtwente.nl">rijssen@pngtwente.nl</a>
                                    </p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="formRow">
                                            <label>
                                                <span>Je naam:</span>
                                            </label>
                                            <input
                                                id="formNaam"
                                                type="text"
                                                {...register('name', {
                                                    required: 'Vul je naam in.',
                                                    maxLength: 80,
                                                    pattern: {
                                                        value: /^([A-ZÀ-ÿ][-,a-z. ']+[ ]*)*(\s([A-ZÀ-ÿ][-,a-z. ']+[ ]*)*)/i,
                                                        message: 'Vul een voor- en achternaam in.',
                                                    },
                                                })}
                                            />
                                            {errors.name ? <div className="error">{errors.name.message}</div> : ''}
                                        </div>
                                        <div className="formRow">
                                            <label>
                                                <span>Je telefoonnummer:</span>
                                            </label>
                                            <input
                                                id="formTel"
                                                type="tel"
                                                {...register('telephone', {
                                                    required: 'Vul een telefoonnummer in.',
                                                    pattern: {
                                                        value: /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/i,
                                                        message: 'Vul geldig telefoonnummer in.',
                                                    },
                                                })}
                                            />
                                            {errors.telephone ? (
                                                <div className="error">{errors.telephone.message}</div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="submitGroup">
                                            <input className="btn btn--small btn--ghost btn--emphasis" type="submit" />
                                        </div>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                        <h2>
                                            <ApolloMarkdown inline>**Bedankt** voor je aanvraag</ApolloMarkdown>
                                        </h2>
                                    </div>
                                    <p>Wij bellen je zo spoedig mogelijk terug!</p>
                                </>
                            )}
                        </div>
                        <div className="col col--6 imageCol">
                            <div className="image">
                                <div className="content">
                                    <img src={image} alt="" />
                                </div>
                                <div className="background">
                                    <BackgroundComponent height={10} width={8} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
            <div className="container attribution">
                <div className="row">
                    <div className="col col--12">
                        <div className="bandwerk">
                            Ontwerp en realisatie:{' '}
                            <a target="_blank" href="https://bandwerk.nl" rel="noreferrer">
                                Bandwerk merkenbouwers
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

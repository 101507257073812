import Footer from '../components/Footer';
import HeaderHome from '../components/header/HeaderHome';
import Preloader from '../components/Preloader';
import HomeCalendarSection from '../components/section/HomeCalendarSection';
import HomeConversionSection from '../components/section/HomeConversionSection';
import HomeUspSection from '../components/section/HomeUspSection';
import MembersSection from '../components/section/MembersSections';
import YoutubeSection from '../components/section/YoutubeSection';
import usePage from '../framework/hooks/usePageHook';

export default function HomePage({ page }) {
    const pageObj = usePage(page.id);

    if (!pageObj) return <Preloader />;

    return (
        <>
            <HeaderHome page={pageObj} />
            <HomeCalendarSection />
            <MembersSection title="Leer onze leden kennen" />
            <HomeUspSection />
            <YoutubeSection videoId='Vtz4kGxbN14' />
            <HomeConversionSection page={pageObj} />
        </>
    );
}

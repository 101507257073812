import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { Helmet } from 'react-helmet';
import HomePage from '../views/HomePage';
import ContentPage from '../views/ContentPage';
import useApiUrl from './hooks/useApiUrlHook';

import '../sass/app.scss';
import Navigation from '../components/Navigation';
import MembersPage from '../views/MembersPage';
import SignupPage from '../views/SignupPage';
import MemberPage from '../views/MemberPage';
import Footer from '../components/Footer';
import ContactPage from '../views/ContactPage';
import NotFoundPage from '../views/NotFoundPage';

/**
 * Catchall for unmatched routes reroutes to correct view.
 */
export default function ResolvePage({ detailPage = false }) {
    const { path, id, urlTitle } = useParams();
    const apiUrl = useApiUrl();

    const location = useLocation();

    const [page, setPage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    let pageElement;

    useEffect(() => {
        if (apiUrl === null) return;

        axios
            .post(`${apiUrl}/page`, { path })
            .then((response) => {
                setPage(response.data);
            })
            .catch((error) => {
                setPage({ script: '404', titel: 'Pagina niet gevonden' });
            });
    }, [path, apiUrl]);

    if (page === null) {
        return <div />;
    }

    switch (page.script) {
        case '404':
            return (
                <>
                    <Navigation pageId={page.id} />
                    <NotFoundPage />
                    <Footer />
                </>
            );
            break;
        case 'home':
            pageElement = (
                <>
                    <Navigation pageId={page.id} />
                    <HomePage page={page} />
                    <Footer />
                </>
            );
            break;
        case 'leden':
            if (detailPage) {
                pageElement = (
                    <>
                        <Navigation pageId={page.id} />
                        <MemberPage id={id} page={page} />
                        <Footer />
                    </>
                );
                break;
            }
            pageElement = (
                <>
                    <Navigation pageId={page.id} />
                    <MembersPage page={page} />
                    <Footer />
                </>
            );
            break;
        case 'aanmelden':
            pageElement = (
                <>
                    <Navigation pageId={page.id} />
                    <SignupPage page={page} />
                    <Footer />
                </>
            );
            break;
        case 'contact':
            pageElement = (
                <>
                    <Navigation pageId={page.id} />
                    <ContactPage page={page} />
                    <Footer />
                </>
            );
            break;
        default:
            pageElement = (
                <>
                    <Navigation pageId={page.id} />
                    <ContentPage page={page} />
                    <Footer />
                </>
            );
            break;
    }

    return (
        <>
            <Helmet>
                <title>
                    {page.titletag !== '' ? page.titletag : page.titel} - {process.env.MIX_REACT_APP_APP_NAME}
                </title>
                <meta
                    name="name"
                    content={`${page.titletag !== '' ? page.titletag : page.titel} - ${
                        process.env.MIX_REACT_APP_APP_NAME
                    }`}
                />
                {page.image !== null && page.image !== '' ? (
                    <meta name="image" content={`${apiUrl}/img/exactg/website/1920/1080/100/${page.image}`} />
                ) : null}
                <meta name="description" content={page.description} />
                <meta name="keywords" content={page.keywords} />
            </Helmet>
            {pageElement}
        </>
    );
}

import { useEffect } from 'react';
import ContentBlock from '../components/ContentBlock';
import HeaderSmall from '../components/header/HeaderSmall';
import Preloader from '../components/Preloader';
import useApiUrl from '../framework/hooks/useApiUrlHook';
import usePage from '../framework/hooks/usePageHook';

export default function ContentPage({ page }) {
    const pageObj = usePage(page.id);
    const apiUrl = useApiUrl();

    if (!pageObj) return <Preloader />;

    const image = pageObj.images.split(',')[0];

    return (
        <>
            <HeaderSmall
                title={pageObj.paginatitel ?? pageObj.titel}
                image={image !== '' ? `${apiUrl}/img/ratio/website/888/666/100/${image}` : null}
            />
            <ContentBlock content={pageObj?.inhoud} />
        </>
    );
}

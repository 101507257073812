import axios from 'axios';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import BackgroundComponent from '../BackgroundComponent';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';

// @ts-ignore
import plaatje from '../../assets/images/plaatje.png';

import '../scss/UspSection.scss';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

export default function HomeUspSection() {
    const [isInView, setIsInView] = useState(false);
    const [usps, setUsps] = useState(null);
    const apiUrl = useApiUrl();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        async function getCalendar() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/usp`)
                .then((response) => {
                    setUsps(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
        if (usps === null) {
            getCalendar();
        }
    }, [usps, apiUrl]);

    return (
        <section className="usps usps--home">
            <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--7 left">
                            <div className="inner">
                                <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                    <div className="pre">PNG Twente</div>
                                    <h2>
                                        <ApolloMarkdown inline>Dit is wat ons **onderscheidt**</ApolloMarkdown>
                                    </h2>
                                </div>
                                <ul className="uspList">
                                    {usps?.map((item, key) => {
                                        return <li key={key}>{item.usp}</li>;
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col col--5 imageCol">
                            <div className="image">
                                <div className="content">
                                    <img src={plaatje} alt="" />
                                </div>
                                <ApolloParallax className="background" speed={-0.05}>
                                    <BackgroundComponent height={10} width={16} />
                                </ApolloParallax>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </section>
    );
}

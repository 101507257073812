import { useEffect, useState } from 'react';

export default function useApiUrl() {
    const [apiUrl, setApiUrl] = useState(null);

    useEffect(() => {
        async function getApiUrl() {
            const { hostname } = window.location;
            if (hostname.includes('127.0.0.1') || hostname.includes('localhost')) {
                setApiUrl(process.env.MIX_REACT_APP_API_URL);
            } else if (hostname.includes('192.168.88.126')) {
                setApiUrl(process.env.MIX_REACT_APP_API_URL_LOCAL_REMOTE);
            } else if (hostname.includes('hwid2')) {
                setApiUrl(process.env.MIX_REACT_APP_API_URL_DEV);
            } else {
                setApiUrl(process.env.MIX_REACT_APP_API_URL_PROD);
            }
        }

        getApiUrl();
    }, []);

    return apiUrl;
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMenu from '../framework/hooks/useMenuHook';
import logo from '../assets/Logo.svg';

import './scss/Navigation.scss';

export default function Navigation({ pageId }) {
    const [isActive, setIsActive] = useState(false);

    const nav = useRef(null);
    const menu = useMenu();
    const location = useLocation();

    const handleToggleMenu = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        setIsActive(false);
    }, [location]);

    const handleMenuSpacing = () => {
        if (window.innerWidth < 992) {
            document.querySelector('body').style.paddingTop = `${nav.current.offsetHeight}px`;
        } else {
            document.querySelector('body').style.paddingTop = '0px';
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleMenuSpacing);

        handleMenuSpacing();

        return () => window.removeEventListener('resize', handleMenuSpacing);
    });

    return (
        <nav ref={nav} className={isActive ? 'isActive' : ''}>
            <div className="container">
                <div className="row">
                    <div className="col col--2 logoCol">
                        <Link to="/">
                            <img src={logo} alt="" className="logo" />
                        </Link>
                        <div className="burgerIcon" onClick={handleToggleMenu} role="button" tabIndex={0}>
                            <span>menu</span>
                        </div>
                    </div>
                    <div className="col col--10 menuCol">
                        <ul>
                            {menu?.map((e, i) => (
                                <li className={i === menu.length - 1 ? 'highlight' : ''} key={i}>
                                    <Link className={(pageId ?? -1) === e.id ? 'active' : ''} to={`/${e.url_titel}`}>
                                        {e.titel}
                                    </Link>
                                    {e.sub === null ? (
                                        ''
                                    ) : (
                                        <ul className="sub">
                                            {e.sub.map((e2, i2) => (
                                                <li key={i2}>
                                                    <Link to={`/${e2.url_titel}`}>{e2.titel}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                            <li>
                                <a target={'_blank'} href="https://mijnpng.nl/" className="btn">
                                    Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default function BackgroundComponent({ width, height }) {
    const background = [];

    for (let i = 0; i < height; i += 1) {
        for (let j = 0; j < width; j += 1) {
            background.push(<div key={Math.random()} className="dot" />);
        }
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{background}</>;
}

import React, { useEffect, useState } from 'react';
import './ApolloSlider.scss';

export function ApolloSliderItem({
    widthSpacing = 0,
    width = 0,
    isActive = null,
    spacing = null,
    onClickCallBack,
    children,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
}) {
    const [currentWidthPercentage, setCurrentWidthPercentage] = useState(width);

    useEffect(() => {
        if (currentWidthPercentage !== width) {
            // Almost there, now make it iterate a fixed amount of times...
            setTimeout(() => {
                if (currentWidthPercentage >= width) {
                    // To inactive
                    setCurrentWidthPercentage(Math.floor(currentWidthPercentage * 1000 - 250) / 1000);
                } else {
                    // To active
                    setCurrentWidthPercentage(Math.floor(currentWidthPercentage * 1000 + 250) / 1000);
                }
            }, 8);
        }
    }, [width, currentWidthPercentage]);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            onClick={onClickCallBack}
            className={`apolloSlider-item ${currentWidthPercentage !== width ? 'animating' : ''} ${
                isActive ? 'active' : ''
            }`}
            style={{
                width: `calc(${currentWidthPercentage}% - ${widthSpacing}px)`,
                marginLeft: `${spacing}px`,
                marginRight: `${spacing}px`,
            }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            {children}
        </div>
    );
}

export function ApolloSlider({ children, slidesToShow, activeWidth, spacing, nextButton = null, prevButton = null }) {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const minSwipeDistance = 50;
    let itemWidth = 100;
    let virualSlidesToShow = slidesToShow;
    let activeWidthValue = activeWidth;

    if (activeWidthValue === undefined) {
        activeWidthValue = null;
    } else if (!((slidesToShow ?? 1) === 1)) {
        itemWidth = (itemWidth - activeWidthValue) / (slidesToShow - 1);
        virualSlidesToShow = activeWidthValue / itemWidth + (slidesToShow - 1);
    }

    const updateIndex = (newIndex) => {
        let index = newIndex;
        if (index < 0) {
            index = 0;
        } else if (newIndex >= React.Children.count(children)) {
            index = React.Children.count(children) - 1;
        }

        setActiveIndex(newIndex);
    };

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            updateIndex(activeIndex + 1);
        }

        if (isRightSwipe) {
            updateIndex(activeIndex - 1);
        }
    };

    useEffect(() => {
        if (nextButton == null) return null;

        const handleNext = () => {
            updateIndex(activeIndex + 1);
        };

        if (Array.isArray(nextButton)) {
            nextButton.forEach((item) => item.current.addEventListener('click', handleNext));
        } else {
            nextButton.current.addEventListener('click', handleNext);
        }

        return () => {
            if (Array.isArray(nextButton)) {
                nextButton.forEach((item) => item.current.removeEventListener('click', handleNext));
            } else {
                prevButton.current.removeEventListener('click', handleNext);
            }
        };
    }, [nextButton, activeIndex, children]);

    useEffect(() => {
        if (prevButton == null) return null;

        const handlePrev = () => {
            updateIndex(activeIndex - 1);
        };

        if (Array.isArray(prevButton)) {
            prevButton.forEach((item) => item.current.addEventListener('click', handlePrev));
        } else {
            prevButton.current.addEventListener('click', handlePrev);
        }

        return () => {
            if (Array.isArray(prevButton)) {
                prevButton.forEach((item) => item.current.removeEventListener('click', handlePrev));
            } else {
                prevButton.current.removeEventListener('click', handlePrev);
            }
        };
    }, [prevButton, activeIndex, children]);

    const handleItemClick = (index) => {
        updateIndex(index);
    };

    return (
        <div className="apolloSlider">
            <div
                className="apolloSlider-inner"
                style={{
                    transform: `translateX(-${activeIndex * (100 / (virualSlidesToShow ?? 1))}%)`,
                    marginLeft: `-${spacing}px`,
                    marginRight: `-${spacing}px`,
                }}
            >
                {React.Children.map(children, (child, index) => {
                    const active = index === activeIndex;
                    return React.cloneElement(child, {
                        isActive: active,
                        widthSpacing: spacing * (active ? 4 : 2),
                        width: active ? activeWidthValue : itemWidth,
                        onClickCallBack: () => handleItemClick(index),
                        spacing,
                        onTouchStart: (e) => onTouchStart(e),
                        onTouchMove: (e) => onTouchMove(e),
                        onTouchEnd: (e) => onTouchEnd(e),
                    });
                })}
            </div>
        </div>
    );
}

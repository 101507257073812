import useApiUrl from '../framework/hooks/useApiUrlHook';
import logo from '../assets/Logo.svg';

import './scss/maintenance.scss';

export default function MaintenancePage() {
    const apiUrl = useApiUrl();

    return (
        <section className="maintenance">
            <div className="container">
                <div className="row">
                    <div className="col col--12">
                        <img src={logo} alt="" className="logo" />

                        <h1>Wij leggen de laatste hand aan onze nieuwe website</h1>
                        <p>
                            We nodigen je graag uit om vrijdag 23 december om 10:00 onze nieuwe website te komen
                            bewonderen!
                        </p>
                        <img
                            className="supportingImage"
                            src={`${apiUrl?.replace('/api', '')}/assets/images/corporate/Factory.png`}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import '../scss/header/HeaderSimple.scss';
import '../scss/header/HeaderSmall.scss';

export default function HeaderContact({ title, image, content }) {
    const [isInView, setIsInView] = useState(false);

    const [rawTitle, setTitle] = useState();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        setTitle(title);
    }, [title, image]);

    return (
        <header className="headerSimple headerContact">
            <Waypoint topOffset="25%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--7">
                            <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                <h1>
                                    {rawTitle !== undefined && rawTitle.length > 0 ? (
                                        <ApolloMarkdown inline>{rawTitle}</ApolloMarkdown>
                                    ) : (
                                        ''
                                    )}
                                </h1>
                            </div>
                            <ApolloMarkdown inline={false}>{content}</ApolloMarkdown>
                        </div>
                        <div className="col col--5">
                            <div className="image">
                                <img src={image} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </header>
    );
}

export default function NotFoundPage() {
    return (
        <div className="container">
            <div className="row">
                <div className="col col--12">
                    <div class="underlineTitle active">
                        <h1>
                            <slot>
                                <strong>Oeps!</strong> Deze pagina konden we niet vinden.
                            </slot>
                        </h1>
                        <p>Vervolg je bezoek aan de website via het menu!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

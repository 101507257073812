import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import MemberBlock from '../components/section/members/MemberBlock';
import MembersSection from '../components/section/MembersSections';
import ApolloMarkdown from '../framework/components/ApolloMarkdown';
import useApiUrl from '../framework/hooks/useApiUrlHook';

import './scss/contactCard.scss';

export default function MemberPage({ page, id }) {
    const [isInView, setIsInView] = useState(false);
    const [isInView2, setIsInView2] = useState(false);
    const [member, setMember] = useState(null);
    const apiUrl = useApiUrl();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    const handleInView2 = () => setIsInView2(true);
    const handleOutOfView2 = () => setIsInView2(false);

    useEffect(() => {
        async function getMember() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/members/${id}`)
                .then((response) => {
                    setMember(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }

        if (member === null || member.id !== id) {
            getMember();
        }
    }, [apiUrl, id]);

    return (
        <>
            <Helmet>
                <title>
                    {`${member?.contactpersoon}, ${member?.bedrijfsnaam} - ${process.env.MIX_REACT_APP_APP_NAME}`}
                </title>
            </Helmet>
            <section className="memberDetail">
                <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                    <div className="container">
                        <div className="row">
                            <div className="col col--7">
                                <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                    <h1>
                                        <strong>{member?.contactpersoon}</strong>, aangenaam!
                                    </h1>
                                </div>
                                <ApolloMarkdown inline={false}>{member?.algemene_omschrijving}</ApolloMarkdown>
                            </div>
                            <div className="col col--4">
                                <div className="contactCard">
                                    <div className="inner">
                                        <div className="group">
                                            <div className="portrait">
                                                <img
                                                    src={`${apiUrl}/img/extern/ratio/www.pngtwente.nl/profielfoto/500/500/100/${member?.image}`}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="group">
                                            <div className="value name">
                                                <strong>{member?.contactpersoon}</strong>
                                            </div>
                                            <div className="value function">{member?.contactpersoon_functie}</div>
                                            <div className="value company">{member?.bedrijfsnaam}</div>
                                        </div>
                                        <div className="separator" />
                                        <div className="group">
                                            <div className="value contact">
                                                Mobiel:{' '}
                                                <a
                                                    target="_blank"
                                                    href={`tel:${member?.contactpersoon_mobiel}`}
                                                    rel="noreferrer"
                                                >
                                                    {member?.contactpersoon_mobiel}
                                                </a>
                                            </div>
                                            <div className="value contact">
                                                E-mail:{' '}
                                                <a target="_blank" href={`mailto:${member?.email}`} rel="noreferrer">
                                                    {member?.email}
                                                </a>
                                            </div>
                                            {member?.linkedin !== '' ? (
                                                <div className="value contact">
                                                    <a target="_blank" href={`${member?.linkedin}`} rel="noreferrer">
                                                        Connect op LinkedIn
                                                    </a>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </section>
            <section className="companyDetail">
                <Waypoint bottomOffset="50%" onEnter={handleInView2} onLeave={handleOutOfView2}>
                    <div className="container">
                        <div className="row">
                            <div className="col col--4">
                                <div className="contactCard">
                                    <div className="inner">
                                        {member?.logo ? (
                                            <div className="image image--logo">
                                                <img
                                                    src={`${apiUrl}/img/extern/ratio/www.pngtwente.nl/logo/300/200/100/${member?.logo}`}
                                                    alt=""
                                                />
                                            </div>
                                        ) : (
                                            <div className="group" />
                                        )}
                                        <div className="group">
                                            <div className="value name">
                                                <strong>{member?.bedrijfsnaam}</strong>
                                            </div>

                                            <div className="value">
                                                {member?.adres}, {member?.postcode}, {member?.woonplaats}
                                            </div>
                                            {member?.link !== '' ? (
                                                <div className="value contact">
                                                    <a target="_blank" href={`${member?.link}`} rel="noreferrer">
                                                        Bekijk website
                                                    </a>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="separator" />
                                        <div className="group">
                                            <div className="value contact">
                                                Mobiel:{' '}
                                                <a target="_blank" href={`tel:${member?.telefoon}`} rel="noreferrer">
                                                    {member?.telefoon}
                                                </a>
                                            </div>
                                            <div className="value contact">
                                                E-mail:{' '}
                                                <a
                                                    target="_blank"
                                                    href={`mailto:${member?.email_zakelijk}`}
                                                    rel="noreferrer"
                                                >
                                                    {member?.email_zakelijk}
                                                </a>
                                            </div>
                                            {member?.linkedin_zakelijk !== '' ? (
                                                <div className="value contact">
                                                    <a
                                                        target="_blank"
                                                        href={`${member?.linkedin_zakelijk}`}
                                                        rel="noreferrer"
                                                    >
                                                        Connect op LinkedIn
                                                    </a>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {member?.companyImages !== '' ? null : <div className="group"></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col col--7">
                                <div className={`underlineTitle ${isInView2 ? 'active' : ''}`}>
                                    <h1>
                                        <strong>{member?.bedrijfsnaam}</strong>
                                    </h1>
                                </div>
                                <ApolloMarkdown inline={false}>{member?.bedrijfs_omschrijving}</ApolloMarkdown>
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </section>
            <MembersSection title="Leer ook onze andere leden kennen" id={member?.id} />
        </>
    );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../../framework/hooks/useApiUrlHook';
import '../../scss/members/MembersOverviewSection.scss';
import MemberBlock from './MemberBlock';

export default function MembersOverviewSection() {
    const [members, setMembers] = useState(null);
    const [branches, setBranches] = useState(null);
    const [grid, setGrid] = useState([]);

    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getMembers() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/members`)
                .then((response) => {
                    const leGrid = response.data?.map((item, index) => <MemberBlock key={`m${index}`} item={item} />);

                    setGrid(leGrid);
                    setMembers(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }

        if (members === null) {
            getMembers();
        }
    }, [apiUrl]);

    useEffect(() => {
        async function getBranches() {
            if (apiUrl === null) return;
            if (members === null) return;

            const amount = Math.floor((members.length + members.length / 6) / 6);

            await axios.get(`${apiUrl}/members/branches/${amount}`).then((response) => {
                const leGrid = response.data?.map((item, index) => (
                    <div className="gridItem gridItem--branche" key={index}>
                        <div className="inner">
                            <div className="text">
                                <div className="wrap">
                                    <span>Werkzaam in {item.titel}?</span>
                                    <span>Of een van onze andere vrije branches?</span>
                                    <Link className="btn" to={'/bijeenkomst-bijwonen'}>
                                        Meld je aan!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ));

                setBranches(leGrid);
            });
        }

        if (branches === null) {
            getBranches();
        }
    }, [apiUrl, members]);

    const renderItems = () => {
        if (grid === null || members === null || branches === null) return null;

        const toReturn = [];

        for (let i = 0; i < grid.length; i += 1) {
            toReturn.push(grid[i]);

            if (i % Math.floor(members.length / 6) === 0) {
                const index = i / Math.floor(members.length / 6);
                if (index <= branches.length + members.length) {
                    toReturn.push(branches[index]);
                }
            }
        }

        return toReturn;
    };

    return (
        <section className="membersOverview">
            <div className="container">
                <div className="row">
                    <div className="col col--12">
                        <div className="gridContainer">{renderItems()}</div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import useApiUrl from '../framework/hooks/useApiUrlHook';

import './scss/launch.scss';

export default function LaunchPage() {
    const [count, setCount] = useState(10);
    const [countActive, setCountActive] = useState(false);
    const [launchInitiated, setLaunchInitiated] = useState(false);

    const apiUrl = useApiUrl();

    const initiateLaunch = () => {
        setLaunchInitiated(true);
    };

    const startCountdown = () => {
        setCountActive(true);
    };

    useEffect(() => {
        if (count > 0 && countActive === true) {
            const myInterval = setInterval(() => {
                setCount(count - 1);
            }, 1000);

            return () => clearInterval(myInterval);
        }

        if (countActive) {
            setCountActive(false);
            initiateLaunch();

            axios.post(`${apiUrl}/releaseWebsite`);

            setTimeout(() => {
                window.location.href = apiUrl.replace('/api', '');
            }, 4000);
        }

        return null;
    }, [count, countActive]);

    return (
        <section className={`launch ${launchInitiated ? 'go' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col col--12">
                        <div className={`countDown ${countActive ? 'active' : ''}`}>{count}</div>
                        <h1>De nieuwe PNG Website, klaar om te lanceren</h1>
                        <div className="rocket">
                            <div className="rocket-body">
                                <div
                                    className={`launchSite ${countActive || launchInitiated ? 'hide' : ''}`}
                                    onClick={startCountdown}
                                >
                                    Lanceer!
                                </div>
                                <div className="body" />
                                <div className="fin fin-left" />
                                <div className="fin fin-right" />
                                <div className="window" />
                            </div>
                            <div className="exhaust-flame" />
                            <ul className="exhaust-fumes">
                                <li />
                                <li />
                                <li />
                                <li />
                                <li />
                                <li />
                                <li />
                                <li />
                                <li />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

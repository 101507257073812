import { CookieConsentProvider } from '@use-cookie-consent/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LaunchPage from '../views/LaunchPage';
import MaintenancePage from '../views/MaintenancePage';
import ApolloAnalytics from './components/ApolloAnalytics';
import ApolloCookieConsent from './components/ApolloCookieConsent';
import useApiUrl from './hooks/useApiUrlHook';
import ResolvePage from './ResolvePage';

export default function Apollo() {
    const apiUrl = useApiUrl();
    const [releaseState, setReleaseState] = useState(null);
    const getReleaseState = () => {
        axios.get(`${apiUrl}/releaseStatus`).then((response) => setReleaseState(response.data));
    };

    if (new Date() < new Date('2022-12-23 10:00')) {
        useEffect(() => {
            if (releaseState === null && apiUrl !== null) {
                getReleaseState();
            }
        }, [apiUrl]);

        if (releaseState !== true) {
            return (
                <BrowserRouter>
                    <Helmet>
                        <title>{process.env.MIX_REACT_APP_APP_NAME}</title>
                    </Helmet>
                    <Routes>
                        <Route path="/" element={<MaintenancePage />} />
                        <Route path="/launchcountdown" element={<LaunchPage />} />
                    </Routes>
                </BrowserRouter>
            );
        }
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.MIX_REACT_APP_RECAPTCHA}>
            <BrowserRouter>
                <CookieConsentProvider>
                    <ApolloAnalytics />
                    <Helmet>
                        <title>{process.env.MIX_REACT_APP_APP_NAME}</title>
                    </Helmet>
                    <Routes>
                        <Route path="/">
                            <Route index element={<ResolvePage />} />

                            {/* Project specific routes */}

                            {/* Framework routes */}
                            <Route path=":path">
                                <Route path=":id" element={<ResolvePage />} />
                                <Route path=":id/:urlTitle" element={<ResolvePage detailPage />} />
                                <Route index element={<ResolvePage />} />
                            </Route>
                        </Route>
                    </Routes>
                    <ApolloCookieConsent />
                </CookieConsentProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    );
}

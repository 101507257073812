import axios from 'axios';
import { useEffect, useState } from 'react';
import HeaderContact from '../components/header/HeaderContact';
import Preloader from '../components/Preloader';
import MemberBlock from '../components/section/members/MemberBlock';
import useApiUrl from '../framework/hooks/useApiUrlHook';
import usePage from '../framework/hooks/usePageHook';

import './scss/contactPage.scss';

export default function ContactPage({ page }) {
    const [leaders, setLeaders] = useState(null);

    const apiUrl = useApiUrl();
    const pageObj = usePage(page.id);

    useEffect(() => {
        async function getLeaders() {
            if (apiUrl === null) return;

            await axios.get(`${apiUrl}/members/leaders`).then((response) => {
                console.log(response.data);
                setLeaders(response.data);
            });
        }

        if (leaders === null) {
            getLeaders();
        }
    }, [apiUrl]);

    if (!pageObj) return <Preloader />;

    const image = pageObj.images.split(',')[0];

    return (
        <>
            <HeaderContact
                title={pageObj.paginatitel ?? pageObj.titel}
                image={`${apiUrl}/img/ratio/website/888/666/100/${image}`}
                content={pageObj?.inhoud}
            />
            <section className="leaders">
                <div className="container">
                    <div className="row">
                        <div className="col col--4">
                            <div className="centerWrap">
                                <div className="underlineTitle active">
                                    <h2>
                                        <strong>Leiderschapsteam</strong>
                                    </h2>
                                </div>
                                <p>Dankzij hen draait de club altijd op rolletjes</p>
                            </div>
                        </div>
                        <div className="col col--8">
                            <div className="gridContainer">
                                {leaders?.map((item, index) => (
                                    <MemberBlock key={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <ContentBlock content={pageObj?.inhoud} /> */}
        </>
    );
}

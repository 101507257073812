import ApolloMarkdown from '../framework/components/ApolloMarkdown';

export default function ContentBlock({ content }) {
    return (
        <section className="content">
            <div className="container">
                <div className="row row--center">
                    <div className="col col--8">
                        <ApolloMarkdown inline={false}>{content}</ApolloMarkdown>
                    </div>
                </div>
            </div>
        </section>
    );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import HeaderSplit from '../components/header/HeaderSplit';
import Preloader from '../components/Preloader';
import SignupComponent from '../components/section/SignupComponent';
import useApiUrl from '../framework/hooks/useApiUrlHook';
import usePage from '../framework/hooks/usePageHook';

export default function SignupPage({ page }) {
    const pageObj = usePage(page.id);
    const [branches, setBranches] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getBranches() {
            if (apiUrl === null) return;

            await axios.get(`${apiUrl}/members/branches/100`).then((response) => {
                setBranches(response.data);
            });
        }

        if (branches === null) {
            getBranches();
        }
    }, [apiUrl]);

    if (!pageObj) return <Preloader />;

    const image = pageObj.images.split(',')[0];

    const getExtraContent = () => {
        return (
            <>
                <h2>Werkzaam in een van de vrije branches? Meld je aan!</h2>
                <div className="branches">
                    {branches?.map((item, key) => (
                        <span key={key}>{item.titel}</span>
                    ))}
                </div>
            </>
        );
    };

    return (
        <HeaderSplit
            title={pageObj.titletag !== '' ? pageObj.titletag : pageObj.titel}
            image={`${apiUrl}/img/ratio/website/888/666/100/${image}`}
            content={pageObj.inhoud}
            extraContent={getExtraContent()}
        >
            <SignupComponent />
        </HeaderSplit>
    );
}

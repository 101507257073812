import { useState } from 'react';
import YouTube from 'react-youtube';
import './scss/youtubeSection.scss';

export default function YoutubeSection({ videoId = 'SKfYJcYAQy8' }) {
    const [ambilight, setAmbilight] = useState(null);
    const [video, setVideo] = useState(null);

    const PlayerState = {
        unstarted: -1,
        ended: 0,
        playing: 1,
        paused: 2,
        buffering: 3,
        video_cued: 5,
    };

    const videoReady = (event) => {
        setVideo(event.target);
    };

    const videoStateChange = (event) => {
        switch (event.data) {
            case PlayerState.playing:
                ambilight.seekTo(event.target.getCurrentTime());
                ambilight.playVideo();
                break;
            case PlayerState.paused:
                ambilight.seekTo(event.target.getCurrentTime());
                ambilight.pauseVideo();
                break;
            default:
        }
    };

    const optimizeAmbilight = (event) => {
        const qualityLevels = event.target.getAvailableQualityLevels();
        event.target.mute();
        if (qualityLevels && qualityLevels.length && qualityLevels.length > 0) {
            qualityLevels.reverse();
            const lowestLevel = qualityLevels[qualityLevels.findIndex((q) => q !== 'auto')];
            event.target.setPlaybackQuality(lowestLevel);
        }
    };

    const ambilightReady = (event) => {
        setAmbilight(event.target);
        optimizeAmbilight(event);
    };

    const ambilightStateChange = (event) => {
        // eslint-disable-next-line default-case
        switch (event.data) {
            case PlayerState.buffering:
            case PlayerState.playing:
                optimizeAmbilight(event);
                break;
        }
    };

    return (
        <section className="youtubeSection">
            <div className="container">
                <div className="row">
                    <div className="col col--10">
                        <div className="videoWrapper">
                            <div className="ambilightWrapper">
                                <div className="aspectRatio">
                                    <YouTube
                                        id="ambilight"
                                        videoId={videoId}
                                        onStateChange={ambilightStateChange}
                                        onReady={ambilightReady}
                                    />
                                    <YouTube
                                        id="video"
                                        videoId={videoId}
                                        onStateChange={videoStateChange}
                                        onReady={videoReady}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import Preloader from '../components/Preloader';
import MembersOverviewSection from '../components/section/members/MembersOverviewSection';
import usePage from '../framework/hooks/usePageHook';

import '../components/scss/members/MembersPage.scss';
import HeaderSimple from '../components/header/HeaderSimple';

export default function MembersPage({ page }) {
    const pageObj = usePage(page.id);

    if (!page) return <Preloader />;

    return (
        <div>
            <HeaderSimple title={pageObj?.paginatitel ?? pageObj?.titel} content={pageObj?.inhoud} />
            <MembersOverviewSection />
        </div>
    );
}

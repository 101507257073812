import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import BackgroundComponent from '../BackgroundComponent';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';

import '../scss/HomeConversionSection.scss';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

export default function HomeConversionSection({ page }) {
    const apiUrl = useApiUrl();

    const [isInView, setIsInView] = useState(false);

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    const image = page.images.split(',')[1];

    return (
        <section className="conversion">
            <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--5">
                            <div className="image image--portrait">
                                <div className="content">
                                    <img
                                        src={`${apiUrl}/img/extern/ratio/www.pngtwente.nl/website/550/750/100/${image}`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col col--7 right">
                            <div className="inner">
                                <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                    <div className="pre">Join the club</div>
                                    <h2>
                                        <ApolloMarkdown inline>
                                            Maak kennis met **Twents noaberschap** en **zakelijk lef**
                                        </ApolloMarkdown>
                                    </h2>
                                </div>
                                <div className="inhoud">
                                    <p>Ontdek onze leden, ze voegen jou graag toe aan hun netwerk.</p>
                                </div>
                                <Link className="btn btn--ghost btn--white" to="/onze-leden">
                                    Bekijk onze leden
                                </Link>

                                <ApolloParallax className="background" speed={-0.1}>
                                    <BackgroundComponent height={10} width={18} />
                                </ApolloParallax>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </section>
    );
}

import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import BackgroundComponent from '../BackgroundComponent';
import useApiUrl from '../../framework/hooks/useApiUrlHook';

export default function HeaderHome({ page }) {
    const apiUrl = useApiUrl();

    const [isInView, setIsInView] = useState(false);
    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    const image = page.images.split(',')[0];

    return (
        <header>
            <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--7">
                            <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                <h1>
                                    <ApolloMarkdown inline>{page?.paginatitel ?? page?.titel}</ApolloMarkdown>
                                </h1>
                            </div>
                            <p>
                                <ApolloMarkdown inline={false}>{page?.inhoud}</ApolloMarkdown>
                            </p>
                        </div>
                        <div className="col col--5">
                            <div className="image image--portrait image--brand">
                                <div className="content">
                                    <img
                                        src={`${apiUrl}/img/extern/ratio/www.pngtwente.nl/website/550/750/100/${image}`}
                                        alt=""
                                    />
                                </div>
                                <ApolloParallax className="background" speed={-0.15}>
                                    <BackgroundComponent height={21} width={16} />
                                </ApolloParallax>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </header>
    );
}

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import '../scss/CalendarCardComponent.scss';

require('dayjs/locale/nl');

export default function CalendarCardComponent({ item, active }) {
    const apiUrl = useApiUrl();

    const [moment, setMoment] = useState(null);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [backgroundPath, setBackgroundPath] = useState(null);

    useEffect(() => {
        if (item) {
            if (item.date_start) {
                setMoment(dayjs(item.date_start));
            }
            if (item.titel) {
                setTitle(item.titel);
            }
            if (item.content) {
                setContent(item.content);
            }

            if (item.sponsor !== null && item.sponsor !== 0 && item.has_speaker === 1) {
                setBackgroundPath('Corporate meeting.png');
            } else if (item.id % 2 === 0) {
                setBackgroundPath('Analysing growth.png');
            } else {
                setBackgroundPath('Sealing the deal.png');
            }
        }
    }, [item]);

    return (
        <div className={`calendarCard ${active ? 'active' : ''}`}>
            <img
                alt="bg"
                className="backgroundImage"
                src={`${apiUrl?.replace('/api', '')}/assets/images/corporate/${encodeURIComponent(backgroundPath)}`}
            />
            <div className="content">
                <div className="dateTime">
                    <div className="weekday">{moment?.locale('nl').format('dddd')}</div>
                    <div className="date">{moment?.locale('nl').format('DD MMMM YYYY')}</div>
                </div>
                <div className="title">
                    <div className="block">
                        <h2>{title}</h2>
                        {item?.sponsor_naam !== null ? (
                            <Link
                                to={`/onze-leden/${item.sponsor_id}/${encodeURIComponent(
                                    `${item.sponsor_naam}`.toLowerCase().replace(/ /g, '-').replace(/\*/g, ''),
                                )}`}
                                className="speaker"
                            >
                                Spreker: {item?.sponsor_naam}
                            </Link>
                        ) : null}
                        {content?.length > 0 ? (
                            <div className="styled">
                                {content?.length > 120 ? `${content.substr(0, 120)}...` : content}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="block block-button">
                        <Link to={`/bijeenkomst-bijwonen/${item.id}/aanmelden`} className="btn">
                            Als gast aanmelden
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

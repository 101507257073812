import { useCookieConsentContext } from '@use-cookie-consent/react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

export default function ApolloAnalytics() {
    const { consent } = useCookieConsentContext();

    const location = useLocation();

    useEffect(() => {
        if (consent && consent?.statistics === true) {
            ReactGA.initialize(process.env.MIX_REACT_APP_GA4_TOKEN);
        }
    }, [consent]);

    useEffect(() => {
        if (consent && consent?.statistics === true) {
            ReactGA.send('pageview');
        }
    }, [location]);

    return null;
}

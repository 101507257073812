import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { ApolloSlider, ApolloSliderItem } from '../../framework/components/ApolloSlider/ApolloSlider';

import ApolloMarkdown from '../../framework/components/ApolloMarkdown';

import '../scss/HomeCalendarSection.scss';

import CalendarCardComponent from './CalendarCardComponent';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useMediaSize from '../../framework/hooks/useMediaSize';

export default function HomeCalendarSection() {
    const [isInView, setIsInView] = useState(false);
    const [calendar, setCalendar] = useState(null);
    const apiUrl = useApiUrl();

    const size = useMediaSize();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    const nextButtonRef = useRef();
    const prevButtonRef = useRef();

    const prevButtonMobRef = useRef();
    const nextButtonMobRef = useRef();

    useEffect(() => {
        async function getCalendar() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/calendar`)
                .then((response) => {
                    setCalendar(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
        if (calendar === null) {
            getCalendar();
        }
    }, [calendar, apiUrl]);

    return (
        <section className="calendar calendar--home">
            <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--8">
                            <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                <h1>
                                    <ApolloMarkdown inline>**Onze activiteiten**</ApolloMarkdown>
                                </h1>
                            </div>
                        </div>
                        <div className="col col--4 toBottom">
                            <div className="buttonRow desktop">
                                <div ref={prevButtonRef} className="btn btn--small btn--ghost btn--main btn--prev">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.25"
                                        height="18.25"
                                        viewBox="0 0 18.25 18.25"
                                    >
                                        <path
                                            id="Path_48"
                                            data-name="Path 48"
                                            d="M235.928,175.052a1.4,1.4,0,0,0,.856-.337l7.881-7.466a1.241,1.241,0,0,0,.041-1.765,1.225,1.225,0,0,0-1.752-.037l-5.781,5.47v-12.87a1.244,1.244,0,0,0-2.489,0v12.87l-5.781-5.47a1.229,1.229,0,0,0-1.755.037,1.244,1.244,0,0,0,.044,1.765l7.881,7.466a1.212,1.212,0,0,0,.856.337Z"
                                            transform="translate(175.052 -226.803) rotate(90)"
                                            fill="#00345f"
                                        />
                                    </svg>
                                </div>
                                <div ref={nextButtonRef} className="btn btn--small btn--ghost btn--main btn--next">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.25"
                                        height="18.25"
                                        viewBox="0 0 18.25 18.25"
                                    >
                                        <path
                                            id="Path_48"
                                            data-name="Path 48"
                                            d="M235.928,175.052a1.4,1.4,0,0,0,.856-.337l7.881-7.466a1.241,1.241,0,0,0,.041-1.765,1.225,1.225,0,0,0-1.752-.037l-5.781,5.47v-12.87a1.244,1.244,0,0,0-2.489,0v12.87l-5.781-5.47a1.229,1.229,0,0,0-1.755.037,1.244,1.244,0,0,0,.044,1.765l7.881,7.466a1.212,1.212,0,0,0,.856.337Z"
                                            transform="translate(175.052 -226.803) rotate(90)"
                                            fill="#00345f"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col--8">
                            <p className="sub">
                                <ApolloMarkdown inline>Op onderstaande dagen ontmoeten wij elkaar</ApolloMarkdown>
                            </p>
                            <div className="buttonRow mobile">
                                <div ref={prevButtonMobRef} className="btn btn--small btn--ghost btn--main btn--prev">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.25"
                                        height="18.25"
                                        viewBox="0 0 18.25 18.25"
                                    >
                                        <path
                                            id="Path_48"
                                            data-name="Path 48"
                                            d="M235.928,175.052a1.4,1.4,0,0,0,.856-.337l7.881-7.466a1.241,1.241,0,0,0,.041-1.765,1.225,1.225,0,0,0-1.752-.037l-5.781,5.47v-12.87a1.244,1.244,0,0,0-2.489,0v12.87l-5.781-5.47a1.229,1.229,0,0,0-1.755.037,1.244,1.244,0,0,0,.044,1.765l7.881,7.466a1.212,1.212,0,0,0,.856.337Z"
                                            transform="translate(175.052 -226.803) rotate(90)"
                                            fill="#00345f"
                                        />
                                    </svg>
                                </div>
                                <div ref={nextButtonMobRef} className="btn btn--small btn--ghost btn--main btn--next">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.25"
                                        height="18.25"
                                        viewBox="0 0 18.25 18.25"
                                    >
                                        <path
                                            id="Path_48"
                                            data-name="Path 48"
                                            d="M235.928,175.052a1.4,1.4,0,0,0,.856-.337l7.881-7.466a1.241,1.241,0,0,0,.041-1.765,1.225,1.225,0,0,0-1.752-.037l-5.781,5.47v-12.87a1.244,1.244,0,0,0-2.489,0v12.87l-5.781-5.47a1.229,1.229,0,0,0-1.755.037,1.244,1.244,0,0,0,.044,1.765l7.881,7.466a1.212,1.212,0,0,0,.856.337Z"
                                            transform="translate(175.052 -226.803) rotate(90)"
                                            fill="#00345f"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row slider">
                        <div className="col col--12">
                            <ApolloSlider
                                slidesToShow={size.width <= size.breakpoint.xl ? 1 : 3}
                                activeWidth={size.width <= size.breakpoint.xl ? 100 : 50}
                                spacing={size.width <= size.breakpoint.xl ? 5 : 10}
                                nextButton={[nextButtonRef, nextButtonMobRef]}
                                prevButton={[prevButtonRef, prevButtonMobRef]}
                            >
                                {calendar?.map((item, key) => {
                                    return (
                                        <ApolloSliderItem key={key}>
                                            <CalendarCardComponent item={item} />
                                        </ApolloSliderItem>
                                    );
                                })}
                            </ApolloSlider>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </section>
    );
}

import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import '../scss/header/HeaderSimple.scss';

export default function HeaderSimple({ title, content }) {
    const [isInView, setIsInView] = useState(false);

    const [rawTitle, setTitle] = useState();
    const [rawContent, setContent] = useState();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        setTitle(title);
        setContent(content);
    }, [title, content]);

    return (
        <header className="headerSimple">
            <Waypoint topOffset="25%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <div className="col col--10">
                            <div className={`underlineTitle ${isInView ? 'active' : ''}`}>
                                <h1>
                                    {rawTitle !== undefined && rawTitle.length > 0 ? (
                                        <ApolloMarkdown inline>{rawTitle}</ApolloMarkdown>
                                    ) : (
                                        ''
                                    )}
                                </h1>
                            </div>
                            <p>
                                {rawContent !== undefined && rawContent.length > 0 ? (
                                    <ApolloMarkdown>{rawContent}</ApolloMarkdown>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </header>
    );
}

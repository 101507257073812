import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import MemberBlock from './members/MemberBlock';

export default function MembersSection({ title, id = null }) {
    const [related, setRelated] = useState([]);
    const [prevId, setPrevId] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getRelated() {
            if (apiUrl === null) return;
            await axios
                .get(`${apiUrl}/members/related/${id ?? 9999}`)
                .then((response) => {
                    console.log(response);
                    setRelated(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }

        if (related.length === 0 || prevId !== id) {
            setPrevId(id);
            getRelated();
        }
    }, [apiUrl, id]);

    return (
        <section className="relatedMembers">
            <div className="container">
                <div className="row">
                    <div className="col col--4">
                        <div className="centerWrap">
                            <div className="underlineTitle active">
                                <h2>
                                    <strong>{title}</strong>
                                </h2>
                            </div>
                            <div>
                                <Link className="btn" to="/onze-leden">
                                    Bekijk alle leden
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col col--8">
                        <div className="gridContainer">
                            {related?.map((item, index) => (
                                <MemberBlock key={index} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
